.network-menu-root {
    .network-menu-btn {
        padding: 9px 20px;
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
        border-radius: 10px;
        cursor: pointer;
        margin-right: 20px;
        display: flex;
        align-items: center;

        .network-menu-btn-img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        p {
            font-family: Montserrat SemiBold;
            font-weight: 600;
            font-size: 18px;
            color: #FFFFFF;
        }
    }
}

.network-menu-popper {
    margin-top: 10px;
    margin-right: 15px;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(100px);
    }

    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        background: #181B1C;
    }

    border-radius: 10px;
    overflow: hidden;
    z-index: 10;

    .network-tooltip {
        display: flex;
        flex-direction: column;

        .network-tooltip-item {
            padding: 13px 20px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }

            p {
                font-family: Montserrat SemiBold;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
                text-align: center;
            }

            .network-tooltip-item-img{
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
}