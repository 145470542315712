* {
    box-sizing: border-box;
    font-family: 'Monda', sans-serif;
}

a {
    text-decoration: none;
    color: white;
}

.nextui-table-header-row>th {
    background-color: #121212;
    border-radius: 0px !important;
    color: #E8F101;
}

input {
    border: none;
    outline: 0;
}

.nextui-table-pagination>button {
    background-color: #121212;
    color: #00A59B;

}

.nextui-table-pagination>button:hover {
    background-color: #121212;
}

/* ROTATE */
.rotate {
    animation: rotate-1-keyframes 1s;
}

@keyframes rotate-1-keyframes {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}

.rotate-back {
    animation: rotate-2-keyframes 1s;
}

@keyframes rotate-2-keyframes {
    from {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(360deg);
    }
}
